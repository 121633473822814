import { API } from "aws-amplify";

import geoLocationMapping from "../mappings/locationsHistory";
import actionTypes from "./actionTypes";

function keepValidLocations(location) {
  return location.coordinates[0] && location.coordinates[1];
}
/**
 * Fetch geoloc positions for a truck
 */

function getTruckPositionHistoryRequest() {
  return {
    type: actionTypes.POSITION_HISTORY_TRUCK_FETCH_REQUEST
  };
}

function getTruckPositionHistorySuccess(locationsHistory) {
  return {
    type: actionTypes.POSITION_HISTORY_TRUCK_FETCH_SUCCESS,
    locationsHistory: locationsHistory
      .map(geoLocationMapping)
      .filter(keepValidLocations)
  };
}

function getTruckPositionHistoryFailure(error) {
  return {
    type: actionTypes.POSITION_HISTORY_TRUCK_FETCH_FAILURE,
    error
  };
}

function getTruckPositionHistory(truckId, hours) {
  hours = hours || "2023-06-14";
  return (dispatch, getState) => {
    const state = getState();
    const { selectedCustomer } = state.customer;
    dispatch(getTruckPositionHistoryRequest());
    const urlPath = `geoLocVehicle/${selectedCustomer.fleetCode}/${truckId}?timeFilter=${hours}`;
    return API.get("TruckTrailerMatching", urlPath)
      .then(res => dispatch(getTruckPositionHistorySuccess(res)))
      .catch(error => dispatch(getTruckPositionHistoryFailure(error)));
  };
}

function resetTruckPositionHistory() {
  return {
    type: actionTypes.POSITION_HISTORY_TRUCK_RESET
  };
}

/**
 * Fetch geoloc positions for a matched trailer
 */

function getTrailerPositionHistoryRequest() {
  return {
    type: actionTypes.POSITION_HISTORY_TRAILER_FETCH_REQUEST
  };
}

function getTrailerPositionHistorySuccess(locationsHistory) {
  return {
    type: actionTypes.POSITION_HISTORY_TRAILER_FETCH_SUCCESS,
    locationsHistory: locationsHistory
      .map(geoLocationMapping)
      .filter(keepValidLocations)
  };
}

function getTrailerPositionHistoryFailure(error) {
  return {
    type: actionTypes.POSITION_HISTORY_TRAILER_FETCH_FAILURE,
    error
  };
}

function getTrailerPositionHistory(TrailerDeviceId, hours) {
  hours = hours || "2023-06-14";
  return (dispatch, getState) => {
    const state = getState();
    const { selectedCustomer } = state.customer;
    dispatch(getTrailerPositionHistoryRequest());
    const urlPath = `geoLocVehicle/${selectedCustomer.fleetCode}/${TrailerDeviceId}?timeFilter=${hours}`;
    return API.get("TruckTrailerMatching", urlPath)
      .then(res => dispatch(getTrailerPositionHistorySuccess(res)))
      .catch(erro => dispatch(getTrailerPositionHistoryFailure(erro)));
  };
}

function resetTrailerPositionHistory() {
  return {
    type: actionTypes.POSITION_HISTORY_TRAILER_RESET
  };
}

export default {
  getTruckPositionHistory,
  getTrailerPositionHistory,
  resetTrailerPositionHistory,
  resetTruckPositionHistory
};
