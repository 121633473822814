import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Dropdown } from "antd";

import actions from "../../actions";

const { Item } = Menu;
function customers(state) {
  return state.customer.customers.items;
}
function selectedCustomer(state) {
  return state.customer.selectedCustomer;
}

function FleetSelector() {
  const availableCustomers = useSelector(customers);
  const currentCustomer = useSelector(selectedCustomer);
  const dispatch = useDispatch();
  const selectCustomer = customerId => {
    const customer = availableCustomers.find(
      d => Number(d.fleetCode) === Number(customerId)
    );
    dispatch(actions.customer.selectCustomer(customer));
  };
  if (!availableCustomers) {
    return null;
  }
  if (availableCustomers.length === 0) {
    return null;
  }
  return (
    <Dropdown
      overlay={
        <Menu onClick={({ key: customerId }) => selectCustomer(customerId)}>
          {availableCustomers.map(cust => (
            <Item key={cust.fleetCode}>{cust.fleetName}</Item>
          ))}
        </Menu>
      }
    >
      <p style={{ marginBottom: 0, marginRight: 12, cursor: "pointer" }}>
        {currentCustomer && currentCustomer.fleetName}
      </p>
    </Dropdown>
  );
}

export default FleetSelector;
