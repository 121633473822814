/* eslint-disable eqeqeq */
import produce from "immer";
// import moment from "moment";
import _ from "lodash";

import actionTypes from "../actions/actionTypes";
import matchingsMapping from "../mappings/matchings";
import cleanTruckCouplings from "../mappings/cleanTruckCoupling";
import { createTrailerColorMap } from "../helpers/colors";

const initialState = {
  trucks: {
    items: null,
    loading: false,
    error: null
  },
  truckCouplings: {
    items: null,
    loading: false,
    error: null
  },
  selectedTruck: null,
  selectedTruckId: null
};

function getTruckTripsAndBestMatchingTrailers(matches) {
  let validation = localStorage.getItem("truckTrailerCouplingValidation");
  validation = validation ? JSON.parse(validation) : {};

  const trucks = Object.values(_.groupBy(matches, m => m.truckId)).map(
    truckMatches => {
      const { truckId, truckNumplate } = truckMatches[0];
      const trips = Object.values(_.groupBy(truckMatches, m => m.tripId))
        .map(truckTripMatches => {
          const {
            tripId
            // tripStartTime,
            // tripEndTime,
            // tripDistance,
            // tripDuration,
          } = truckTripMatches[0];
          const bestMatches = Object.values(
            _.groupBy(truckTripMatches, m => m.trailerId)
          )
            .map(trailerMatches =>
              _.minBy(trailerMatches, m => m.pathMedianDistance)
            )
            .filter(bestMatch => bestMatch)
            .reduce((collection, trailer) => collection.concat(trailer), [])
            // .sort((a, b) =>
            //   // a.pathMedianDistance > b.pathMedianDistance ? 1 : -1
            //   a.stars > b.stars ? 1 : -1
            // )
            .sort(
              // first sort on stars, then on median distance
              function(a, b) {
                if (a.stars === b.stars) {
                  return a.pathMedianDistance > b.pathMedianDistance ? 1 : -1;
                }
                return a.stars < b.stars ? 1 : -1;
              }
            )
            .map(m => ({
              ...m,
              validated:
                validation &&
                validation[`${truckId}_${m.trailerId}_${m.tripId}`]
                  ? validation[`${truckId}_${m.trailerId}_${m.tripId}`]
                      .validated
                  : null
            }));

          const tempAlternativeToBestMatches = Object.values(
            _.groupBy(truckTripMatches, m => m.trailerId)
          )
            .map(trailerMatches => _.maxBy(trailerMatches, m => m.confidence))
            .reduce((collection, trailer) => collection.concat(trailer), [])
            .sort((a, b) => (a.confidence < b.confidence ? 1 : -1));

          return {
            tripId,
            // tripStartTime,
            // tripEndTime,
            // tripDuration,
            /* tripDuration: moment(tripEndTime).diff(
            moment(tripStartTime),
            "minutes"
          ), */
            // tripDistance,
            tripStartTime:
              bestMatches.length > 0 ? bestMatches[0].tripStartTime : null,
            tripEndTime:
              bestMatches.length > 0 ? bestMatches[0].tripEndTime : null,
            tripDistance:
              bestMatches.length > 0 ? bestMatches[0].tripDistance : null,
            tripDuration:
              bestMatches.length > 0 ? bestMatches[0].tripDuration : null,
            stars: bestMatches.length > 0 ? bestMatches[0].stars : null,
            validated:
              bestMatches.length > 0 &&
              validation &&
              validation[`${truckId}_${bestMatches[0].trailerId}_${tripId}`]
                ? validation[`${truckId}_${bestMatches[0].trailerId}_${tripId}`]
                    .validated
                : null,
            bestMatchTrailerId:
              bestMatches.length > 0 ? bestMatches[0].trailerId : null,
            bestMatchTrailerNumPlate:
              bestMatches.length > 0 ? bestMatches[0].trailerNumPlate : null,
            bestMatchAverageDistance:
              bestMatches.length > 0 ? bestMatches[0].pathMedianDistance : null,
            bestMatches,
            tempAlternativeToBestMatches,
            highFreqTrailer:
              bestMatches.length > 0 ? bestMatches[0].highFreqTrailer : null
          };
        })
        .map(t => ({
          ...t,
          score:
            t.bestMatchAverageDistance != null
              ? (t.tripDuration > 5 ? 0.5 : 0) +
                (t.tripDistance > 5 ? 0.5 : 0) +
                (4 -
                  4 *
                    Math.min(
                      1,
                      Math.max(0, t.bestMatchAverageDistance - 0.1) / 0.6
                    ))
              : null
        }))
        .sort((a, b) => (a.tripStartTime < b.tripStartTime ? 1 : -1));

      return {
        truckId,
        trips,
        truckNumplate,
        latestTripStartTime: trips[0].tripStartTime,
        latestTripEndTime: trips[0].tripEndTime,
        latestTripDuration: trips[0].tripDuration,
        latestTripDistance: trips[0].tripDistance,
        latestBestMatchAverageDistance: trips[0].bestMatchAverageDistance,
        latestBestMatchTrailerId: trips[0].bestMatchTrailerId,
        bestMatchTrailerNumPlate: trips[0].bestMatchTrailerNumPlate,
        score: trips[0].score,
        stars: trips[0].stars,
        highFreqTrailer: trips[0].highFreqTrailer
      };
    }
  );

  return trucks;
}

function transformTimeOfExecution(timeOfExecution) {
  const st = `${timeOfExecution}`;
  if (st.length === 14) {
    return (timeOfExecution * 1000) / 10000.0;
  }
  if (st.length == 10) {
    return timeOfExecution * 1000;
  }
  return timeOfExecution;
}

export default function trucksReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_REQUEST:
        draft.trucks.loading = true;
        break;
      case actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_SUCCESS:
        const cleanedMatches = action.matchings
          // .filter(m => m.info)
          .map(matchingsMapping);
        const trucks = getTruckTripsAndBestMatchingTrailers(cleanedMatches);
        draft.trucks.items = trucks;
        draft.trucks.loading = false;
        break;
      case actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_FAILURE:
        draft.trucks.error = action.error;
        draft.trucks.loading = false;
        break;
      case actionTypes.TRUCK_TRAILER_MATCHING_SELECT_TRUCK:
        draft.selectedTruckId = action.truckId;
        if (state.trucks) {
          // eslint-disable-next-line eqeqeq
          const selectedTruck = state.trucks.items.find(
            t => t.truckId === action.truckId
          );
          draft.selectedTruck = selectedTruck;
        }
        break;
      case actionTypes.VALIDATE_COUPLING:
        if (state.trucks && state.trucks.items) {
          // eslint-disable-next-line eqeqeq
          if (
            state.selectedTruck &&
            state.selectedTruck.id !== action.truckId
          ) {
          }
          const index = state.trucks.items.findIndex(
            t => t.truckId === action.truckId
          );
          draft.selectedTruck.trips = state.selectedTruck.trips.map(trip => {
            if (trip.tripId === action.tripId) {
              return { ...trip, validated: action.validated };
            }
            return trip;
          });
          draft.trucks.items[index] = draft.selectedTruck;
        }
        break;
      case actionTypes.RESET_VALIDATION:
        if (state.trucks && state.trucks.items) {
          draft.trucks.items.forEach(truck => {
            truck.trips.forEach(trip => {
              trip.validated = null;
            });
          });
        }
        break;
      case actionTypes.GET_TRUCK_COUPLINGS_REQUEST:
        draft.truckCouplings.items = null;
        draft.truckCouplings.error = null;
        draft.truckCouplings.loading = true;
        break;
      case actionTypes.GET_TRUCK_COUPLINGS_SUCCESS:
        const truckApiCallsMapping = action.truckApiCalls.reduce(
          (mapping, truck) => {
            mapping[truck.truckPlate] = truck.apiCalls;
            return mapping;
          }, 
          {} // initial value
        );

        const cleanedTruckCouplings = action.truckCouplings
          .map(cleanTruckCouplings)
          .map(truck => {
            // generate a color scheme once and for all
            if (truck.couplings) {
              const uniqueIds = new Set(truck.couplings.map(e => e.trailerID));
              const trailerColorMap = createTrailerColorMap([...uniqueIds]);
              return { ...truck, trailerColorMap };
            }
            return truck;
          })
          .map(truck => {
            return {
              ...truck,
              apiCalls: truckApiCallsMapping[truck.truckPlate]
                ? truckApiCallsMapping[truck.truckPlate].map(apiCall => {
                    return {
                      ...apiCall,
                      timeOfExecution: new Date(
                        transformTimeOfExecution(apiCall.timeOfExecution)
                      )
                    };
                  })
                : null
            };
          });

        draft.truckCouplings.items = cleanedTruckCouplings;
        draft.truckCouplings.loading = false;
        break;
      case actionTypes.GET_TRUCK_COUPLINGS_FAILURE:
        draft.truckCouplings.items = null;
        draft.truckCouplings.error = action.error;
        draft.truckCouplings.loading = false;
        break;
      default:
        return draft;
    }
  });
}
