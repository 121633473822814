import { API } from "aws-amplify";
import actionTypes from "./actionTypes";
import cleanUrl from "../helpers/cleanUrl";

function getTruckHistoricCouplingRequest() {
  return {
    type: actionTypes.GET_TRUCK_COUPLINGS_REQUEST
  };
}

function getTruckHistoricCouplingFailure(error) {
  return {
    type: actionTypes.GET_TRUCK_COUPLINGS_FAILURE,
    error
  };
}

function getTruckHistoricCouplingSuccess(truckCouplings, truckApiCalls) {
  const apiCallToShow = truckApiCalls.map(apiCall => {
    const { truckPlate, apiCalls } = apiCall;
    return {
      truckPlate,
      apiCalls
    };
  });
  return {
    type: actionTypes.GET_TRUCK_COUPLINGS_SUCCESS,
    truckCouplings,
    truckApiCalls: apiCallToShow
  };
}

async function loopApi(urlPath) {
  let lastKey;
  let results = [];
  do {
    const url = `${urlPath}${
      lastKey
        ? `?firstkey=${JSON.stringify(lastKey)}&timeFilter=1000`
        : "?timeFilter=1000"
    }`;
    const cleanedUrl = cleanUrl(url);
    const callResults = await API.get("TruckTrailerMatching", cleanedUrl);
    const { trucks, pagination } = callResults;
    lastKey = pagination.lastKey;
    results = [].concat(results, trucks);
  } while (lastKey);
  return results;
}

async function loopApi2(urlPath) {
  let lastKey;
  let results = [];
  do {
    const url = `${urlPath}${
      lastKey
        ? `?firstkey=${JSON.stringify(lastKey)}&timeFilter=1200`
        : "?timeFilter=1200"
    }`;
    const cleanedUrl = cleanUrl(url);
    const callResults = await API.get("TruckTrailerMatching", cleanedUrl);
    const { trucks, pagination } = callResults;
    lastKey = pagination.lastKey;
    results = [].concat(results, trucks);
  } while (lastKey);
  return results;
}

function getTruckHistoricCoupling() {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedCustomer } = state.customer;
    dispatch(getTruckHistoricCouplingRequest());
    const urlPath2 = `truck/${selectedCustomer.fleetCode}`;
    const urlPath = `couplings/${selectedCustomer.fleetCode}/all`;
    return Promise.all([loopApi(urlPath), loopApi2(`${urlPath2}/api-calls`)])
      .then(([couplings, apiCallsRow]) => {
        const apiCallsToUse = apiCallsRow.map(apiCall => {
          const { truckPlate, apiCalls } = apiCall;
          return {
            truckPlate,
            apiCalls
          };
        });
        return dispatch(
          getTruckHistoricCouplingSuccess(couplings, apiCallsToUse)
        );
      })
      .catch(err => dispatch(getTruckHistoricCouplingFailure(err)));
  };
}

export default {
  getTruckHistoricCoupling
};
